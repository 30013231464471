import React from "react";
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import './main.css';
import App from "./App";
import store, {persistor} from "./store";
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";


const root = ReactDOM.createRoot(
    document.getElementById('app') as HTMLElement,
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);