import React from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../store";


function PrivateRoute() {
    const location = useLocation()
    const auth = useSelector((state: RootState) => state.auth);
    return auth.account ? <Outlet/> : <Navigate to="/login" state={{from: location}} replace/>;
}

export default PrivateRoute;