import React from 'react'
import {
    Routes,
    Route,
    Outlet,
    Navigate,
    useLocation
} from "react-router-dom";
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {useDispatch, useSelector} from "react-redux";
import store, {RootState} from "./store";
import sessionSlice from "./store/slices/session";
import ScrollToTop from "./utils/Scroll";
import PrivateRoute from "./utils/PrivateRoute";
import Login from "./auth/Login";
import {Private, Public} from "./navbar/Navbar";
import Dashboard from "./private/Dashboard";
import Landing from "./public/Landing";
import AboutUs from "./public/AboutUs";
import Solutions from "./public/Solutions";
import favicon from '/static/iso.svg'

export default function App() {

    const auth = useSelector((state: RootState) => state.auth);
    const location = useLocation()
    const dispatch = useDispatch();
    dispatch(sessionSlice.actions.setSessionLocation({location: location.pathname}))
    const route = store.getState().session.location
    const routeHyphen = route?.replace(/-/g, ' ')
    const routeString = routeHyphen?.replace(/\//g, ' | ').toString()

    function toTitleCase(str: string) {
        return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
    }

    const routeLocation = (routeString ? toTitleCase(routeString) : null)

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <link rel="stylesheet" href="https://rsms.me/inter/inter.css"/>
                    <link rel="shortcut icon" href={favicon}/>
                    <title>{`Volta${(routeLocation == " | ") ? " | Soluciones en almacenamiento y generación de energía eléctrica" : routeLocation}`}</title>
                </Helmet>
            </HelmetProvider>

            <div className="min-h-full">
                <ScrollToTop/>
                <Routes>
                    <Route path="/" element={<Container/>}>
                        <Route path="/" element={<Landing/>}/>
                        <Route path="nosotros" element={<AboutUs/>}/>
                        <Route path="soluciones" element={<Solutions/>}/>

                    </Route>
                    <Route path="admin" element={<PrivateRoute/>}>
                        <Route index element={<Navigate to="dashboard" replace/>}/>
                        <Route path="dashboard" element={<Container/>}>
                            <Route index element={<Dashboard/>}/>
                        </Route>
                    </Route>
                    <Route path="login" element={<Login/>}/>
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                </Routes>
            </div>
        </>
    )
}

export function Container() {

    const auth = useSelector((state: RootState) => state.auth);

    return (
        <>
            <div className="pt-16">
                {auth.account ? <Private/> : <Public/>}
                <div className="flex flex-col h-screen">
                    <div className="flex-grow">
                        <Outlet/>
                    </div>
                </div>
            </div>
        </>
    )
}

