import React, {useEffect, useState} from 'react'
import store from "../store";
import {ClockIcon, ShieldCheckIcon} from "@heroicons/react/24/outline";
import axios from "axios";
import moment from "moment/moment";
import "moment-timezone";
import Url from "../utils/url";
import iso from '/static/iso.svg'

export default function Dashboard() {

    return (
        <>
            <Header/>
            <Notifications/>
        </>
    )
}

export function Header() {

    return (
        <>
            <header className="relative bg-white/30 shadow">
                <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8 flex items-center justify-between">
                    <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">Dashboard</h1>
                    <div className="flex mt-0 lg:flex-shrink-0">
                    </div>
                </div>
            </header>
        </>
    )
}

export function Notifications() {

    const {token} = store.getState().auth
    const [customer, setCustomer] = useState<any[]>([])

    useEffect(() => {
        axios
            .get(Url("/customer/contact/"), {
                headers: {
                    "Authorization": 'Bearer ' + token
                }
            })
            .then((res => {
                setCustomer(res.data)
            }))
            .catch((err => {
                console.error(err)
            }))
    }, [])

    console.log(customer)

    return (
        <div className="max-w-7xl mx-auto pt-4 px-4 sm:pt-6 ">
            <div className="bg-white rounded border shadow py-4 px-6 sm:py-6 sm:px-8">
                <h2 className="text-xl font-bold tracking-tight text-gray-800">
                    Cotizaciones pendientes
                </h2>
                {(customer.length == 0) ? (
                    <div className="p-1 overflow-auto h-full sm:h-96">
                        <div className="flex justify-center grid grid-rows-2 my-10 gap-y-2 pt-0 sm:pt-16">
                            <div className="flex justify-center">
                                <ShieldCheckIcon className="block h-14 w-14 text-gray-300"/>
                            </div>
                            <div>
                                <p className="block text-md font-semibold text-gray-500 text-center">
                                    ¡Estás al día!
                                </p>
                                <p className="block text-sm text-gray-500 text-center">
                                    No tienes actividades pendientes.
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className="p-5 my-4 bg-gray-50 rounded-lg border border-gray-100">
                        <ol className="divide-y divide-gray-200">
                            {customer && customer.map((i, index) => (
                                <li className="">
                                    <a href="#"
                                       className="block items-center rounded-lg p-3 sm:flex hover:bg-gray-100">
                                        <img className="mr-3 mb-3 w-12 h-12 rounded-full sm:mb-0"
                                             src={iso} alt="Contacto"/>
                                        <div className="text-gray-600">
                                            <div className="text-base font-normal">
                                                <div
                                                    className="font-medium text-gray-900 inline">{i["name"]}
                                                </div>
                                                {' '}
                                                <div
                                                    className="font-medium text-gray-900 inline">{i["lastname"]}
                                                </div>
                                                {i['company'] ? ' de ' : ''}
                                                <div
                                                    className="font-medium text-gray-900 inline">{i["company"]}
                                                </div>
                                                {' '}
                                                pidió una
                                                {' '}
                                                <div className="font-medium text-gray-900 inline">
                                                    cotización gratis.
                                                </div>
                                            </div>
                                            <div
                                                className="text-sm font-normal">{i["state"]} | {i["email"]} | {i["telephone"] ? i["telephone"].replace(/(\d{2})\D?(\d{4})\D?(\d{4})/, "($1) $2 $3") : null}
                                            </div>
                                            <div
                                                className="inline-flex items-center text-xs font-normal text-gray-500">
                                                <ClockIcon className="mr-1 w-3 h-3 text-gray-700"/>
                                                {moment(i["created_at"]).format("YYYY-MM-DD HH:mm:ss")}
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ol>
                    </div>
                )}
            </div>
        </div>
    )
}