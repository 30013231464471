import React from "react";
import {
    CheckBadgeIcon,
    CurrencyDollarIcon,
    ArrowPathIcon,
    Battery100Icon, WrenchScrewdriverIcon, GlobeAltIcon, ArrowTrendingUpIcon, CheckIcon
} from '@heroicons/react/24/outline'
import background from "/static/background.png";
import {DefaultFooter} from "./Landing";
import megapack from '/static/megapack.jpeg'
import hybrid from '/static/hybrid.jpeg'
import autonomous from '/static/autonomous.jpeg'

export default function Solutions() {

    return (
        <>
            <div className="absolute inset-0 bg-bottom bg-no-repeat w-auto min-w-full min-h-full max-w-none">
                <img src={background} alt="fondo"
                     className=" absolute inset-0 h-full w-full object-cover object-center"/>
            </div>
            <Header/>
            <div className="flex flex-col h-screen">
                <div className="flex-grow">
                    <Services/>
                    <Benefits/>
                </div>
                <DefaultFooter/>
            </div>
        </>
    )
}

export function Header() {

    return (
        <>
            <header className="relative bg-white/30 shadow">
                <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8 flex items-center justify-between">
                    <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">Soluciones</h1>
                    <div className="flex mt-0 lg:flex-shrink-0">
                    </div>
                </div>
            </header>
        </>

    )
}

export function Services() {

    const services = [
        {
            category: 'Almacenamiento parcial sin generación',
            text: 'Consiste en cargar elementos de almacenamiento en el horario de menor costo tarifario para sustituir el consumo de mayor costo y eliminando la demanda coincidente (capacidad).',
            image: megapack,
            characteristics: [
                'Sistema de almacenamiento (BESS)',
                'Inversores',
                'Tablero de control',
                'Equipo para visualizar datos',
                'Dispositivo de arranque automático'
            ]
        },
        {
            category: 'Sistema híbrido',
            text: 'Incorpora elementos de generación intermitente para suministro directo al centro de carga del usuario durante el segmento tarifario “intermedio”.',
            image: hybrid,
            characteristics: [
                'Sistema de almacenamiento (BESS)',
                'Generación fotovoltáica',
                'Generación eólica',
                'Reguladores de carga',
                'Inversores',
                'Tablero de control',
                'Equipo para visualizar datos',
                'Dispositivo de arranque automático'
            ]
        },
        {
            category: 'Sistema autónomo',
            text: 'Sistema aislado no interconectado de generación de energía (intermitente, base o híbrida) para suministro directo del usuario y carga de elementos de almacenamiento que respaldan potencia y consumo.',
            image: autonomous,
            characteristics: [
                'Sistema de almacenamiento (BESS)',
                'Generación fotovoltáica',
                'Generación eólica',
                'Reguladores de carga',
                'Inversores',
                'Tablero de control',
                'Equipo para visualizar datos',
                'Dispositivo de arranque automático'
            ]
        },
    ]

    return (
        <>

            <div className="relative max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
                <h2 className="mt-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center">
                    Proyectos integrales <p className="text-emerald-600">llave en mano.</p>
                </h2>
                <div className="mt-10 grid grid-cols-3 gap-y-6 gap-x-6 sm:gap-y-8 sm:gap-x-8">
                    {services.map((item, index) => (
                        <div key={index} className="col-span-3 sm:col-span-1">
                            <div className="bg-white/50 backdrop-blur rounded-lg border border-gray-200 shadow-md">
                                <img className="rounded-t-lg h-64 w-full object-cover object-center" src={item.image}
                                     alt=""/>
                                <div className="p-5">
                                    <div>
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                            {item.category}
                                        </h5>
                                        <p className="mb-3 font-normal text-gray-700">
                                            {item.text}
                                        </p>
                                    </div>
                                    <div className="my-4 border-b"/>
                                    <p className="mb-4 font-medium text-gray-700">
                                        Qué incluye:
                                    </p>
                                    <div>
                                        <ul className="space-y-3 max-w-md list-inside">
                                            {item?.characteristics?.map((i, index) => (
                                                <li key={index} className="flex items-center">
                                                    <CheckIcon className="h-6 w-6 mr-1.5 inline text-emerald-700"
                                                               aria-hidden="true"/>
                                                    {i}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </>
    )
}

export function Benefits() {

    const benefits = [
        {
            category: 'Calidad',
            text: 'Disponer de energía de alta calidad, sin cortes o interrupciones, y en cualquier momento que se necesite.',
            icon: <CheckBadgeIcon className="h-6 w-6 inline text-emerald-700" aria-hidden="true"/>
        },
        {
            category: 'Ahorro',
            text: 'Disminuir el costo del servicio de energía eléctrica, a través de sistemas eficientes de administración y gestión.',
            icon: <CurrencyDollarIcon className="h-6 w-6 inline text-emerald-700" aria-hidden="true"/>
        },
        {
            category: 'Respaldo',
            text: 'Garantizar la disposición de energía eléctrica, tanto en demanda (kW) como en consumo (kWh).',
            icon: <ArrowPathIcon className="h-6 w-6 inline text-emerald-700" aria-hidden="true"/>
        },
        {
            category: 'Independencia',
            text: 'Generar energía por fuentes renovables o sistemas híbridos, en el mismo punto de consumo; reducir hasta niveles mínimos la dependencia del suministro de Red.',
            icon: <Battery100Icon className="h-6 w-6 inline text-emerald-700" aria-hidden="true"/>
        },
        {
            category: 'Modernización',
            text: 'Actualizar las instalaciones eléctricas para eliminar pérdidas y alcanzar factores de eficiencia superiores al 98%.',
            icon: <WrenchScrewdriverIcon className="h-6 w-6 inline text-emerald-700" aria-hidden="true"/>

        },
        {
            category: 'Cobertura',
            text: 'Desarrollar sistemas aislados en zonas donde no existe suministro eléctrico.',
            icon: <GlobeAltIcon className="h-6 w-6 inline text-emerald-700" aria-hidden="true"/>
        },
        {
            category: 'Valor',
            text: 'Incorporar productos y servicios de valor agregado al almacenamiento, generación y eficiencia en materia de energía eléctrica.',
            icon: <ArrowTrendingUpIcon className="h-6 w-6 inline text-emerald-700" aria-hidden="true"/>
        },

    ]

    return (
        <>
            <div className="relative max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                <h2 className="mb-6 text-3xl font-bold leading-8 tracking-tight text-emerald-600 text-center sm:text-4xl">
                    Beneficios de nuestros sistemas:
                </h2>
                <div className="max-w-5xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                    <ol className="relative border-l border-gray-300">
                        {benefits.map((item, index) => (
                            <li key={index} className="mb-10 ml-6">
                                <div
                                    className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-white rounded-full ring-8 ring-white">
                                    {item.icon}
                                </div>
                                <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900">
                                    {item.category}
                                </h3>
                                <p className="mb-4 text-base font-normal text-gray-700">
                                    {item.text}
                                </p>
                            </li>
                        ))}
                    </ol>

                </div>
            </div>
        </>
    )
}