export default function Url(path: string) {

    if (process.env.NODE_ENV === 'production') {
        return (
            `${process.env.API_URL + path}`
        )
    } else {
        return (
            `${process.env.LOCALHOST + path}`
        )
    }
}