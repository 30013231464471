import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from '../store';
import authSlice from '../store/slices/auth';
import Url from './url'

const axiosInstance = axios.create({
    baseURL: Url("/"),
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(async (config) => {
    const {token} = store.getState().auth;

    if (token !== null) {
        // @ts-ignore
        config.headers.Authorization = 'Bearer ' + token;}
    return config;
});

axiosInstance.interceptors.response.use(
    (res) => {
        return Promise.resolve(res);
    },
    (err) => {
        return Promise.reject(err);
    }
);

// @ts-ignore
const refreshAuthLogic = async (failedRequest) => {
    const {refreshToken} = store.getState().auth;
    if (refreshToken !== null) {
        return axios
            .post(
                '/auth/refresh/',
                {
                    refresh: refreshToken,
                },
                {
                    baseURL: Url("/")
                }
            )
            .then((resp) => {
                const {access, refresh} = resp.data;
                failedRequest.response.config.headers.Authorization = 'Bearer ' + access;
                store.dispatch(
                    authSlice.actions.setAuthTokens({token: access, refreshToken: refresh})
                );
            })
            .catch((err) => {
                store.dispatch(authSlice.actions.setExtend({extend: true}))

            });
    }
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

export function fetcher<T = any>(url: string) {
    return axiosInstance.get<T>(url).then((res) => res.data);
}

export default axiosInstance;