import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CustomerResponse} from "../../types";

type State = {
    customer: CustomerResponse | null;
};

const initialState: State = {customer: null};

const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {

        setCustomer(state: State, action: PayloadAction<CustomerResponse>) {
            state.customer = action.payload;
        }
    },
});

export default customerSlice;