import React, {useEffect, useState, Fragment} from 'react'
import {useForm, SubmitHandler} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {ToastContainer, toast} from 'react-toastify';
import {Dialog, Transition} from '@headlessui/react'
import axios from "axios";
import volta from '/static/volta.mp4'
import {Link} from "react-router-dom";
import logo from '/static/volta.svg'
import intermepro from '/static/intermepro.png'
import tesla from '/static/tesla.png'
import {ShieldCheckIcon, BoltIcon, LightBulbIcon, Battery100Icon, CheckCircleIcon} from '@heroicons/react/24/outline'
import Slider from "react-slick";
import megapack from '/static/megapack.jpeg'
import bornay from '/static/bornay.jpeg'
import panel from '/static/panel.jpg'
import schneider from '/static/schneider.png'
import poster from '/static/poster.png'

export default function Landing() {

    return (
        <>
            <div className="absolute z-30 left-0 right-0 pt-8">
                <div className="max-w-full mx-auto">
                    <div className="relative items-center">
                        <Hero/>
                    </div>
                    <div className="lg:mt-14 bg-white pt-6 px-4 sm:px-6 lg:px-8">
                        <Solutions/>
                        <Benefits/>
                        <Videos/>
                        <div className="mt-6 bg-gradient-to-r from-transparent via-emerald-100 to-transparent">
                            <CallToAction/>
                        </div>
                        {/*<Brands/>*/}
                        <DefaultFooter/>
                    </div>
                </div>
            </div>

            <div className="relative flex -top-16 items-center justify-center h-screen overflow-hidden">
                <video autoPlay muted loop playsInline controls={false} poster={poster}
                       className="absolute z-10 w-auto min-w-full min-h-full max-w-none">
                    <source src={volta} type="video/mp4"/>
                </video>
                <div
                    className="absolute z-20 min-w-full min-h-full max-w-none w-auto bg-gradient-to-r from-black/80 to-transparent"
                />
            </div>
        </>
    )
}

export function Hero() {

    const [geolocation, setGeolocation] = useState(null)
    const [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    useEffect(() => {
        axios
            .get('https://geolocation-db.com/json/')
            .then((res) => {
                setGeolocation(res.data)
            })
            .catch((err) => {
                console.error(err)
            })
    }, [])

    type ContactValues = {
        name: string;
        lastname: string;
        email: string;
        telephone: string;
        company: string;
        state: string;
        geolocation: string;
        privacy: boolean;
    }

    const schema = Yup.object().shape({
        name: Yup.string()
            .required("Por favor, ingresa tu nombre.")
            .max(254, '¡El texto es muy largo!'),
        privacy: Yup.boolean()
            .oneOf([true])
    })

    const {register, handleSubmit, reset, formState, formState: {errors}} = useForm<ContactValues>({
        defaultValues: {
            name: "",
            lastname: "",
            email: "",
            telephone: "",
            company: "",
            state: "",
            geolocation: "",
            privacy: false,
        },
        resolver: yupResolver(schema)
    });

    const onSubmit: SubmitHandler<ContactValues> = data => {
        const form = {...data, geolocation: geolocation}
        axios
            .post('http://localhost:8000/api/customer/contact/', form)
            .catch((err) => {
                console.error(err)
            });
    }

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset({
                name: "",
                lastname: "",
                email: "",
                telephone: "",
                company: "",
                state: "",
                privacy: false,
            });
            toast.success("Gracias por contactarnos.", {
                position: "bottom-right",
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                bodyClassName: "text-sm font-medium text-gray-800"
            })
        }
    }, [formState, reset])

    return (
        <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-2 gap-y-4 gap-x-4 items-center sm:gap-y-6 sm:gap-x-6">
                    <div className="col-span-2 lg:col-span-1">
                        <h1 className="text-4xl text-center sm:text-left font-bold tracking-tight drop-shadow text-white sm:text-5xl md:text-6xl">
                            <span className="block">Soluciones en</span>{' '}
                            <span className="block text-emerald-500 inline">almacenamiento</span>{' '}
                            <span className="block inline">y</span>{' '}
                            <span className="block text-emerald-500 inline">generación</span>{' '}
                            <span className="block">de energía eléctrica.</span>{' '}
                        </h1>
                        <div className="mt-8 sm:flex justify-start">
                            <div className="mt-3 sm:mt-0">
                                <Link
                                    to="soluciones"
                                    className="flex w-full items-center justify-center rounded-md border border-white backdrop-blur-sm bg-white/75 px-8 py-3 text-base font-semibold text-emerald-900 hover:bg-emerald-100/80 md:py-4 md:px-10 md:text-lg"
                                >
                                    Conocer más
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1 mt-3 md:mt-4 lg:mt-6">
                        <div className="backdrop-blur bg-white/60 rounded-lg shadow p-6 sm:p-8">
                            <h2 className="text-2xl font-extrabold tracking-tight text-gray-800">
                                ¡Sé parte de la solución ahora!
                            </h2>
                            <p className="mt-2 text-sm text-gray-900">
                                Compárnenos tu información de contacto para obtener una cotización personalizada y
                                completamente gratuita.
                            </p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mt-4 grid grid-cols-2 gap-y-4 gap-x-4 sm:gap-y-6 sm:gap-x-6">
                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="name"
                                               className="block text-sm font-medium text-gray-700">
                                            Nombre(s)
                                        </label>
                                        <input
                                            id="name"
                                            type="text"
                                            className={errors.name ? "mt-1 ring-rose-500 border-rose-500 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm rounded-md"
                                                : "mt-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            }
                                            {...register("name")}
                                        />
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="lastname"
                                               className="block text-sm font-medium text-gray-700">
                                            Apellidos
                                        </label>
                                        <input
                                            id="lastname"
                                            type="text"
                                            className={errors.lastname ? "mt-1 ring-rose-500 border-rose-500 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm rounded-md"
                                                : "mt-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            }
                                            {...register("lastname")}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <label htmlFor="email"
                                               className="block text-sm font-medium text-gray-700">
                                            Correo electrónico
                                        </label>
                                        <input
                                            id="email"
                                            type="text"
                                            className={errors.email ? "mt-1 ring-rose-500 border-rose-500 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm rounded-md"
                                                : "mt-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            }
                                            {...register("email")}
                                        />
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="telephone"
                                               className="block text-sm font-medium text-gray-700">
                                            Teléfono
                                        </label>
                                        <input
                                            id="telephone"
                                            type="text"
                                            className={errors.telephone ? "mt-1 ring-rose-500 border-rose-500 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm rounded-md"
                                                : "mt-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            }
                                            {...register("telephone")}
                                        />
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="company"
                                               className="block text-sm font-medium text-gray-700">
                                            Empresa
                                        </label>
                                        <input
                                            id="company"
                                            type="text"
                                            className={errors.company ? "mt-1 ring-rose-500 border-rose-500 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm rounded-md"
                                                : "mt-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            }
                                            {...register("company")}
                                        />
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="state"
                                               className="block text-sm font-medium text-gray-700">
                                            Estado
                                        </label>
                                        <select
                                            id="state"
                                            {...register("state")}
                                            className="mt-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        >
                                            <option>Aguascalientes</option>
                                            <option>Baja California</option>
                                            <option>Baja California Cur</option>
                                            <option>Campeche</option>
                                            <option>Chihuahua</option>
                                            <option>Coahuila</option>
                                            <option>Colima</option>
                                            <option>Chiapas</option>
                                            <option>Ciudad de México</option>
                                            <option>Durango</option>
                                            <option>Guerrero</option>
                                            <option>Guanajuato</option>
                                            <option>Hidalgo</option>
                                            <option>Jalisco</option>
                                            <option>Estado de México</option>
                                            <option>Michoacán</option>
                                            <option>Morelos</option>
                                            <option>Nuevo León</option>
                                            <option>Nayarit</option>
                                            <option>Oaxaca</option>
                                            <option>Puebla</option>
                                            <option>Quintana Roo</option>
                                            <option>Querétaro</option>
                                            <option>Sinaloa</option>
                                            <option>San Luis Potosí</option>
                                            <option>Sonora</option>
                                            <option>Tabasco</option>
                                            <option>Tlaxcala</option>
                                            <option>Tamaulipas</option>
                                            <option>Veracruz</option>
                                            <option>Yucatán</option>
                                            <option>Zacatecas</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="mt-6 flex items-center justify-between">
                                    <div className="flex items-center">
                                        <input
                                            id="privacy"
                                            {...register("privacy")}
                                            type="checkbox"
                                            className="h-4 w-4 text-emerald-600 focus:ring-emerald-500 border-gray-300 rounded"
                                        />
                                        <label htmlFor="privacy" className="ml-2 block text-sm text-gray-900">
                                            He leído y acepto los términos establecidos en el <a href="#"
                                                                                                 onClick={openModal}
                                                                                                 className="text-blue-600 hover:underline hover:font-semibold">Aviso
                                            de Privacidad</a>. Tus
                                            datos personales están protegidos.
                                        </label>
                                    </div>
                                </div>
                                <div className="pt-6 text-center">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center rounded-md border border-white bg-emerald-400/80 py-2 px-4 text-sm font-semibold text-emerald-900 shadow hover:bg-emerald-500/80 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                                    >
                                        Solicitar cotización gratis
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="block fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Aviso de Privacidad
                                    </Dialog.Title>
                                    <div className="h-96 overflow-y-auto mt-4">
                                        <article className="prose">
                                            <p>
                                                De conformidad con lo establecido en la <em>Ley Federal de Protección
                                                de
                                                Datos
                                                Personales en Posesión de los Particulares</em>, <strong>VOLTA ESS MX,
                                                S.A.P.I. de
                                                C.V.</strong>
                                                (en lo sucesivo <strong>VOLTA</strong>),
                                                pone a su disposición el siguiente aviso de privacidad.
                                            </p>
                                            <p>
                                                <strong>VOLTA</strong> es
                                                responsable del uso y
                                                protección de sus datos personales. En este sentido y atendiendo las
                                                obligaciones legales establecidas
                                                en la <em>Ley Federal de Protección de Datos Personales en Posesión de
                                                los
                                                Particulares</em>, a través de este
                                                instrumento se informa a los titulares de los datos, la información que
                                                de
                                                ellos se recaba y los fines
                                                que se le darán a dicha información.
                                            </p>
                                            <p>
                                                <strong>VOLTA</strong> tiene su
                                                domicilio en Bosque de Icacos 134, Bosques de las Lomas,
                                                Cuajimalpa de Morelos, C.P., 05120, CDMX.
                                                Los datos personales que recabamos de usted serán utilizados para las
                                                siguientes finalidades, las
                                                cuales son necesarias para concretar nuestra relación con usted así
                                                como
                                                atender los servicios y/o
                                                pedidos que solicite:
                                            </p>
                                            <li>
                                                Proyectos, evaluación, análisis, financiamiento, instalacion,
                                                operación,
                                                mentenimieto, etc., para
                                                sistemas de generación, almacenaje, administración y gestión de
                                                energía
                                                eléctrica.
                                                Para llevar a cabo las finalidades descritas en el presente aviso de
                                                privacidad, utilizaremos los
                                                siguientes datos personales:
                                            </li>
                                            <li>
                                                Personales, Técnicos, Financieros, Legales, etc. de personas físicas
                                                y/o morales.
                                                Por otra parte, informamos a usted, que sus datos personales serán
                                                compartidos con las siguientes
                                                autoridades, empresas, organizaciones o personas distintas a nosotros:
                                            </li>
                                            <li>
                                                Empresas de administración, suministro, almacenamiento, generación,
                                                gestión y distribución de
                                                energía eléctrica; entidades financieras y/o arrendamientos.
                                                Su información será compartida exclusivamente para los fines que a
                                                continuación se mencionan:
                                            </li>
                                            <li>
                                                Realización de los proyectos de generación, almacenamiento, gestión,
                                                suministro, operación y
                                                distribución de energía eléctricam así como para la evaluación,
                                                viabiidad y contratación de
                                                financiamientos y/o arrendamiento de los mismos.
                                            </li>
                                            <p>
                                                Usted tiene en todo momento el derecho a:

                                            </p>
                                            <p>i) conocer cuales son sus
                                                datos personales que tenemos;</p>
                                            <p>ii) el
                                                uso y/o utilidad de los mismos;</p>
                                            <p>iii) las condiciones en que les damos a
                                                terceros el acceso a los mismos.</p>
                                            <p> Por lo tanto, es su derecho en todo momento:</p>
                                            <p>i) solicitar la corrección
                                                de su información personal en
                                                caso de que esté desactualizada, sea inexacta o incompleta
                                                (Rectificación); </p>
                                            <p>ii) eliminar su
                                                información de nuestros registros o bases de datos, cuando considere
                                                que la misma no está siendo
                                                utilizada adecuadamente (Cancelación); </p>
                                            <p>iii) oponerse al uso de sus
                                                datos personales para fines
                                                específicos (Oposición).</p>
                                            <p>Estos derechos se conocen como derechos ARCO.</p>
                                            <p>
                                                Para el ejercicio de cualquiera de los derechos ARCO, se deberá
                                                presentar la solicitud respectiva por
                                                escrito, mediante el envío de una carta o solicitud en formato libre a
                                                la siguiente dirección: Bosque de Icacos 134, Bosques de las Lomas,
                                                Cuajimalpa de Morelos, C.P., 05120, CDMX.
                                            </p>
                                            <p>
                                                Lo anterior también servirá para conocer el procedimiento y requisitos
                                                para el ejercicio de los
                                                derechos ARCO.
                                            </p>
                                            <p>
                                                La respuesta a la solicitud se dará en 3 días hábiles y se
                                                comunicará a través de correo
                                                electrónico.
                                            </p>
                                            <p>
                                                Los datos de contacto de la persona o departamento de datos personales,
                                                que está a cargo de dar
                                                trámite a las solicitudes de derechos ARCO, son los siguientes:
                                            </p>
                                            <p>
                                                a) Nombre del responsable: Departamento de Contacto.
                                            </p>
                                            <p>
                                                b) Domicilio: Bosque de Icacos 134, Bosques de las Lomas,
                                                Cuajimalpa de Morelos, C.P., 05120, CDMX.
                                            </p>
                                            <p>
                                                c) Teléfono: +52 (55) 5035 7228.
                                            </p>
                                            <p>
                                                d) Correo electrónico: contacto@voltaenergy.mx
                                            </p>
                                            <p>
                                                En cualquier momento usted puede revocar: </p>
                                            <p>i) su consentimiento para el
                                                uso de sus datos personales;</p>
                                            <p>ii)
                                                el consentimiento que, en su caso, nos haya otorgado para el tratamiento
                                                de sus datos personales.</p>
                                            <p>Es
                                                importante que tenga en cuenta que no en todos los casos podremos
                                                atender su solicitud o concluir el
                                                uso
                                                de forma inmediata, ya que es posible que por alguna obligación legal
                                                se requiera seguir tratando
                                                sus
                                                datos personales. Asimismo, usted deberá considerar que para ciertos
                                                fines, la revocación de su
                                                consentimiento implicará que no podamos seguir prestando el servicio
                                                que nos solicitó, o la
                                                conclusión de su relación con nosotros.
                                            </p>
                                            <p>
                                                Para revocar el consentimiento que usted otorga en este acto o para
                                                limitar su divulgación, se
                                                deberá
                                                presentar la solicitud respectiva por escrito, mediante el envío de una
                                                carta o solicitud en
                                                formato
                                                libre a la siguiente dirección:
                                                Bosque de Icacos 134, Bosques de las Lomas,
                                                Cuajimalpa de Morelos, C.P., 05120, CDMX.
                                            </p>
                                            <p>
                                                Del mismo modo, podrá solicitar la información para conocer el
                                                procedimiento y requisitos para la
                                                revocación del consentimiento, así como limitar el uso y divulgación
                                                de su información personal.
                                                En cualquier caso, la respuesta a las peticiones se dará a conocer en
                                                un plazo de 3 días hábiles.
                                            </p>
                                            <p>
                                                El presente aviso de privacidad puede sufrir modificaciones, cambios o
                                                actualizaciones derivadas de
                                                nuevos requerimientos legales; de nuestras propias necesidades por los
                                                productos o servicios que
                                                ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro
                                                modelo de negocio, o por
                                                otras
                                                causas, por lo cual, nos comprometemos a mantenerlo informado sobre los
                                                cambios que pueda sufrir el
                                                presente aviso de privacidad, sin embargo, usted puede solicitar
                                                información sobre si el mismo ha
                                                sufrido algún cambio a través del siguiente correo electrónico:
                                                contacto@voltaenergy.mx
                                            </p>
                                        </article>
                                    </div>

                                    <div className="mt-4 text-center">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Aceptar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

        </>
    )
}

export function Solutions() {

    const features = [
        {
            name: 'El aliado de las renovables',
            description:
                'Gracias al progreso tecnológico, hoy es posible acumular energía para que esté disponible las 24 horas del día. Todo gracias a los sistemas de almacenamiento de energía.',
            icon: BoltIcon,
        },
        {
            name: 'Disponibilidad y rentabilidad',
            description:
                'La producción a gran escala de sistemas de baterías permite que el almacenamiento se afiance más rápidamente, garantizando un rendimiento cada vez mayor.',
            icon: LightBulbIcon,
        },
        {
            name: 'Transición energética',
            description:
                'Gracias a los sistemas de almacenamiento, las energías renovables pueden conseguir un nuevo impulso, haciendo que el proceso de transición energética sea aún más rápido.',
            icon: Battery100Icon,
        },
        {
            name: 'Confiabilidad',
            description:
                'El almacenamiento permite ofrecer servicios para la seguridad del sistema eléctrico (reserva estática, regulación de la frecuencia, voltaje y restablecimiento).',
            icon: ShieldCheckIcon,
        },

    ]


    return (
        <>
            <div className="max-w-7xl mx-auto">
                <div className="lg:text-center">
                    <h2 className="text-lg font-semibold text-emerald-600">Soluciones</h2>
                    <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">Instalaciones
                        personalizadas llave en mano.</p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">Genera y almacena tu propia energía
                        para
                        utilizarla en el momento que la necesites.</p>
                </div>
                <div className="mt-10">
                    <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative">
                                <dt>
                                    <div
                                        className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-emerald-500 text-white">
                                        <feature.icon className="h-6 w-6" aria-hidden="true"/>
                                    </div>
                                    <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{feature.name}</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </>
    )
}

export function Benefits() {

    const features = [
        {
            name: 'Experiencia',
            description: 'Nuestro equipo directivo cuenta con un profundo conocimiento del mercado eléctrico.'
        },
        {name: 'Talento', description: 'El personal operativo es certificado por los fabricantes de equipos.'},
        {name: 'Cobertura', description: 'Tenemos una red nacional de distribuidores y comercializadores.'},
        {
            name: 'Respaldo',
            description: 'Contamos con el apoyo de integradores internacionales para dimensionamiento y puesta en marcha de sistemas de almacenamiento y generación.'
        },
        {name: 'Legal', description: 'Nuestras instalaciones no requieren autorización gubernamental.'},
        {
            name: 'Fiscal',
            description: 'Instalar nuestros equipos te hacen acreedor a diversos beneficios fiscales a nivel local y federal.'
        },
    ]

    return (
        <>
            <div className="mt-8">
                <div className="lg:text-center">
                    <h2 className="text-lg font-semibold text-emerald-600">
                        Beneficios
                    </h2>
                    <h2 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                        ¿Por qué elegir <p className="inline italic">Volta</p>?
                    </h2>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                        Somos una empresa mexicana líder con más de 20 distribuciones en materia de almacenamiento,
                        generación y electrónica de potencia.
                    </p>
                </div>
                <div
                    className="mx-auto grid max-w-5xl grid-cols-1 gap-x-8 pt-6 px-4 sm:px-6 sm:pt-8 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                    <div>
                        <dl className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                            {features.map((feature) => (
                                <div key={feature.name} className="border-t border-gray-200 pt-4">
                                    <dt className="text-lg font-medium leading-6 text-gray-900">
                                        <CheckCircleIcon className="h-6 w-6 inline text-emerald-700"
                                                         aria-hidden="true"/>{' '}
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-2 text-base text-gray-500">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                    <div className="mt-8 lg:mt-0 grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
                        <img
                            src={megapack}
                            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                            className="h-full w-full object-cover object-center rounded-lg bg-gray-100"
                        />
                        <img
                            src={bornay}
                            alt="Top down view of walnut card tray with embedded magnets and card groove."
                            className="h-full w-full object-cover object-center rounded-lg bg-gray-100"
                        />
                        <img
                            src={panel}
                            alt="Side of walnut card tray with card groove and recessed card area."
                            className="h-full w-full object-cover object-center rounded-lg bg-gray-100"
                        />
                        <img
                            src={schneider}
                            alt="Walnut card tray filled with cards and card angled in dedicated groove."
                            className="h-full w-full object-cover object-center rounded-lg bg-gray-100"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export function Brands() {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 4000,
        pauseOnHover: true,
    };

    return (
        <>
            <div className="lg:text-center">
                <h2 className="text-lg font-semibold text-emerald-600">Nuestras marcas</h2>
                <Slider {...settings}>
                    <img className="object-contain h-40" src={intermepro} alt="Intermepro"/>
                    <img className="object-contain h-40" src={tesla} alt="Tesla"/>
                    <img className="object-contain h-40" src={tesla} alt="Tesla"/>
                    <img className="object-contain h-40" src={tesla} alt="Tesla"/>
                    <img className="object-contain h-40" src={tesla} alt="Tesla"/>
                </Slider>
            </div>
        </>
    )
}

export function Videos() {

    return (
        <>
            <div className="mt-8">
                <div className="text-center">
                    <h2 className="text-lg font-semibold text-emerald-600">
                        Multimedia
                    </h2>
                    <h2 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                        <p className="inline italic">Volta</p> en los medios.
                    </h2>
                    <div className="max-w-2xl mx-auto py-4">
                        <div className="aspect-w-16 aspect-h-9">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/RMCTfCozKTc?start=14"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function CallToAction() {

    return (
        <>
            <div>
                <div
                    className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        <span className="block">¿Listo para ser parte de la solución?</span>
                        <span className="block text-emerald-600">Contáctanos ahora mismo.</span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div className="inline-flex rounded-md shadow">
                            <a
                                href="#"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-5 py-3 text-base font-medium text-white hover:bg-emerald-700"
                            >
                                Contacto
                            </a>
                        </div>
                        <div className="ml-3 inline-flex rounded-md shadow">
                            <Link
                                to="soluciones"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-emerald-600 hover:bg-emerald-50"
                            >
                                Saber más
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function DefaultFooter() {

    const navigation = [
        {name: 'Soluciones', href: '/soluciones'},
        {name: 'Nosotros', href: '/nosotros'},
        {name: 'Distribuidores', href: '/distribuidores'},
        {name: 'Noticias', href: '/noticias'},
        {name: 'Contacto', href: '/contacto'}
    ]

    return (
        <>
            <footer className="p-4 bg-white border-t sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto">
                    <div className="sm:flex mt-2 items-center sm:justify-between">
                        <Link to={'/'} className="flex items-center mb-4 sm:mb-0">
                            <img src={logo} className="mr-3 h-8" alt="Logo"/>
                        </Link>
                        <ul className="flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0">
                            {navigation.map((item, index) => (
                                <li key={index}>
                                    <Link to={item.href} className="mr-4 hover:underline md:mr-6 ">
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                            <li>
                                <Link to="/login" className="font-bold tracking-tight hover:underline">Entrar</Link>
                            </li>
                        </ul>
                    </div>
                    <hr className="my-6 border-gray-200 sm:mx-auto"/>
                    <div className="mb-4">
                        <div className="block text-sm text-gray-500 text-center">©2022 <Link
                            to="/" className="hover:underline font-medium">Volta ESS MX, S.A.P.I. de C.V.</Link>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}