import React, {Fragment, useEffect, useRef, useState} from "react";
import {Link, Navigate, NavLink, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import authSlice from "../store/slices/auth";
import useSWR from 'swr';
import {fetcher} from "../utils/axios";
import {UserResponse} from "../types";
import {RootState} from "../store";
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {BellIcon, Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import Refresh from "../auth/Refresh";
import userIcon from '/static/user-icon.png'
import volta from '/static/volta.svg'

interface LocationState {
    userId: string;
}

export function Public() {

    let navigate = useNavigate()

    const navigation = [
        {name: 'Soluciones', href: '/soluciones'},
        {name: 'Nosotros', href: '/nosotros'},
        {name: 'Distribuidores', href: '/distribuidores'},
        {name: 'Noticias', href: '/noticias'},
        {name: 'Contacto', href: '/contacto'}
    ]

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    const [openPanel, setOpenPanel] = useState(true)

    const disclosureRef = useRef(null)

    function useOutsideClick(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpenPanel(false)
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [])
    }

    useOutsideClick(disclosureRef)

    return (
        <>
            <Disclosure as="nav" ref={disclosureRef}
                        className="fixed top-0 left-0 right-0 z-40 backdrop-blur bg-white/80 border-b border-slate-200">
                {({open}) => (
                    <>
                        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                            <div className="relative flex items-center justify-between  h-16">
                                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                    {/* Mobile menu button*/}
                                    <Disclosure.Button
                                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-emerald-600">
                                        <span className="sr-only">Abrir menú principal</span>
                                        {open && openPanel ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                                        ) : (
                                            <Bars3Icon onClick={() => setOpenPanel(true)} className="block h-6 w-6"
                                                       aria-hidden="true"/>
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div
                                    className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                    <div className="flex-shrink-0 flex items-center">
                                        <Link to="/">
                                            <img
                                                className="h-8"
                                                src={volta}
                                                alt="Workflow"
                                            />
                                        </Link>
                                    </div>
                                    <div className="hidden sm:block sm:ml-6">
                                        <div className="flex space-x-4">
                                            {navigation.map((item) => (
                                                // @ts-ignore
                                                <NavLink
                                                    key={item.name}
                                                    to={item.href}
                                                    className={({isActive}) => classNames(
                                                        isActive ? 'bg-slate-200 text-slate-800' : 'text-slate-800 hover:bg-slate-200',
                                                        'px-3 py-2 rounded-md text-sm font-semibold leading-6')}
                                                    // @ts-ignore
                                                    aria-current={({isActive}) => isActive ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </NavLink>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                    <button
                                        type="button"
                                        onClick={() => navigate("/login")}
                                        className="p-1 rounded-full text-sm text-black font-bold tracking-tight hover:text-slate-800"
                                    >Entrar
                                    </button>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel
                            className={(openPanel) ? "sm:hidden" : "hidden"}
                        >
                            <div className="px-2 pt-2 pb-3 space-y-1">
                                {navigation.map((item) => (
                                    // @ts-ignore
                                    <Disclosure.Button
                                        as={NavLink}
                                        key={item.name}
                                        to={item.href}
                                        // @ts-ignore
                                        className={({isActive}) => classNames(
                                            isActive ? 'bg-slate-300 text-slate-800' : 'text-slate-800 hover:bg-slate-200',
                                            'block px-3 py-2 rounded-md text-base font-semibold leading-6'
                                        )}
                                        // @ts-ignore
                                        aria-current={({isActive}) => isActive ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </>
    )
}

export function Private() {

    const account = useSelector((state: RootState) => state.auth.account);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    // @ts-ignore
    const userId = account?.id as LocationState
    const {data: user} = useSWR<UserResponse>(`/user/${userId}/`, fetcher)
    // @ts-ignore
    const userName = user ? user.username : undefined

    const handleLogout = () => {
        dispatch(authSlice.actions.setLogout())
        navigate("/")
    };

    const userProfile = {
        name: 'Tom Cook',
        email: 'tom@example.com',
        imageUrl: userIcon,
    }

    const navigation = [
        {name: 'Soluciones', href: '/soluciones'},
        {name: 'Nosotros', href: '/nosotros'},
        {name: 'Distribuidores', href: '/distribuidores'},
        {name: 'Noticias', href: '/noticias'},
        {name: 'Contacto', href: '/contacto'}
    ]

    const userNavigation = [
        {name: userName, href: '/perfil', key: 'perfil'},
        {name: 'Dashboard', href: '/admin/dashboard', key: 'dashboard'},
    ]

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    const [openPanel, setOpenPanel] = useState(true)

    const disclosureRef = useRef(null)

    function useOutsideClick(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpenPanel(false)
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [])
    }

    useOutsideClick(disclosureRef)

    return (
        <>
            <Disclosure as="nav" ref={disclosureRef}
                        className="fixed top-0 left-0 right-0 z-40 backdrop-blur bg-white/80 border-b border-slate-200">
                {({open}) => (
                    <>
                        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                            <div className="relative flex items-center justify-between  h-16">
                                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                    {/* Mobile menu button*/}
                                    <Disclosure.Button
                                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        <span className="sr-only">Abrir menú principal</span>
                                        {open && openPanel ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                                        ) : (
                                            <Bars3Icon onClick={() => setOpenPanel(true)} className="block h-6 w-6"
                                                       aria-hidden="true"/>
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div
                                    className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                    <div className="flex-shrink-0 flex items-center">
                                        <Link to="/">
                                            <img
                                                className="h-8"
                                                src={volta}
                                                alt="Workflow"
                                            />
                                        </Link>
                                    </div>
                                    <div className="hidden sm:block sm:ml-6">
                                        <div className="flex space-x-4">
                                            {navigation.map((item) => (
                                                // @ts-ignore
                                                <NavLink
                                                    key={item.name}
                                                    to={item.href}
                                                    className={({isActive}) => classNames(
                                                        isActive ? 'bg-slate-300 text-black' : 'text-black hover:bg-slate-200',
                                                        'px-3 py-2 rounded-md text-sm font-medium')}
                                                    // @ts-ignore
                                                    aria-current={({isActive}) => isActive ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </NavLink>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                    <button
                                        type="button"
                                        className="p-1 rounded-full text-slate-500 hover:text-slate-800"
                                    >
                                        <span className="sr-only">Ver notificaciones</span>
                                        <BellIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>

                                    {/* Profile dropdown */}
                                    <Menu as="div" className="ml-3 relative">
                                        <div>
                                            <Menu.Button
                                                className="max-w-xs bg-slate-600 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-400 focus:ring-white">
                                                <span className="sr-only">Menú de usuario</span>
                                                <img className="h-8 w-8 rounded-full" src={userProfile.imageUrl}
                                                     alt=""/>
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {userNavigation.map((item) => (
                                                    <Menu.Item key={item.key}>
                                                        {({active}) => (
                                                            <Link
                                                                to={item.href}
                                                                className={classNames(
                                                                    active ? 'bg-gray-100' : '',
                                                                    'block px-4 py-2 text-sm capitalize text-gray-700'
                                                                )}
                                                            >
                                                                {item.name}
                                                            </Link>
                                                        )}
                                                    </Menu.Item>

                                                ))}
                                                <Menu.Item key="logout">
                                                    {({active}) => (
                                                        <button
                                                            onClick={() => handleLogout()}
                                                            className={classNames(
                                                                active ? 'bg-red-100' : 'bg-red-50',
                                                                'block w-full font-medium text-left px-4 py-2 text-sm text-gray-700'
                                                            )}
                                                        >
                                                            Cerrar sesión
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel
                            className={(openPanel) ? "sm:hidden" : "hidden"}
                        >
                            <div className="px-2 pt-2 pb-3 space-y-1">
                                {navigation.map((item) => (
                                    // @ts-ignore
                                    <Disclosure.Button
                                        as={NavLink}
                                        key={item.name}
                                        to={item.href}
                                        // @ts-ignore
                                        className={({isActive}) => classNames(
                                            isActive ? 'bg-slate-300 text-black' : 'text-black hover:bg-slate-200',
                                            'block px-3 py-2 rounded-md text-base font-semibold tracking-tight'
                                        )}
                                        // @ts-ignore
                                        aria-current={({isActive}) => isActive ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            <Refresh/>
        </>
    )
}