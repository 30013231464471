import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type State = {
    token: string | null;
    location: string | null
}

const initialState: State = {token: null, location: null};

const sessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        setSessionToken(
            state: State,
            action: PayloadAction<{ token: string; }>
        ) {
            state.token = action.payload.token;
        },
        setSessionLocation(
            state: State,
            action: PayloadAction<{ location: string; }>
        ) {
            state.location = action.payload.location;
        }
    }
})

export default sessionSlice;