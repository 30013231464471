import React from "react";
import {Link} from "react-router-dom";
import background from "/static/background.png";
import megapack from '/static/megapack.jpeg'
import autonomous from '/static/autonomous.jpeg'
import hybrid from '/static/hybrid.jpeg'
import bornay from '/static/bornay.jpeg'
import panel from '/static/panel.jpg'
import paneles from '/static/paneles.jpeg'
import powerwall from '/static/powerwall.png'
import {DefaultFooter} from "./Landing";

export default function AboutUs() {

    return (
        <>
            <div className="absolute inset-0 bg-bottom bg-no-repeat w-auto min-w-full min-h-full max-w-none">
                <img src={background} alt="fondo" className=" absolute inset-0 h-full w-full object-cover object-center"/>
            </div>
            <Header/>
            <div className="flex flex-col h-screen">
                <div className="flex-grow">
                    <Content/>
                    <Values/>
                </div>
                <DefaultFooter/>
            </div>

        </>
    )
}

export function Header() {

    return (
        <>
            <header className="relative bg-white/30 shadow">
                <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8 flex items-center justify-between">
                    <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">¿Quiénes somos?</h1>
                    <div className="flex mt-0 lg:flex-shrink-0">
                    </div>
                </div>
            </header>
        </>

    )
}

export function Content() {

    return (
        <>
            <div className="relative overflow-hidden">
                <div className="pt-4 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
                    <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
                        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                            <div className="sm:max-w-lg">
                                <h1 className="font text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                    <span className="block">Somos una empresa</span>{' '}
                                    <span className="block text-emerald-600 inline">mexicana.</span>
                                </h1>
                                <p className="mt-4 text-xl text-gray-600">
                                    Dedicada al almacenamiento y generación de energía eléctrica, integrada por
                                    personal con amplia experiencia en el uso, aprovechamiento, administración y
                                    gestión del recurso eléctrico.

                                </p>
                            </div>
                            <div>
                                <div className="mt-10">
                                    <div
                                        aria-hidden="true"
                                        className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                                    >
                                        <div
                                            className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                                            <div className="flex items-center space-x-6 lg:space-x-8">
                                                <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                                    <div
                                                        className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                                                        <img
                                                            src={megapack}
                                                            alt=""
                                                            className="h-full w-full object-cover object-center"
                                                        />
                                                    </div>
                                                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                        <img
                                                            src={autonomous}
                                                            alt=""
                                                            className="h-full w-full object-cover object-center"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                        <img
                                                            src={hybrid}
                                                            alt=""
                                                            className="h-full w-full object-cover object-center"
                                                        />
                                                    </div>
                                                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                        <img
                                                            src={panel}
                                                            alt=""
                                                            className="h-full w-full object-cover object-center"
                                                        />
                                                    </div>
                                                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                        <img
                                                            src={bornay}
                                                            alt=""
                                                            className="h-full w-full object-cover object-center"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                        <img
                                                            src={paneles}
                                                            alt=""
                                                            className="h-full w-full object-cover object-center"
                                                        />
                                                    </div>
                                                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                        <img
                                                            src={powerwall}
                                                            alt=""
                                                            className="h-full w-full object-cover object-center"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Link
                                        to="/soluciones"
                                        className="inline-block rounded-md border border-transparent bg-emerald-600 py-3 px-8 text-center font-medium text-white hover:bg-emerald-700"
                                    >
                                        Soluciones
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function Values() {

    return (
        <>
            <div className="relative max-w-7xl mx-auto py-8 mb-8 px-4 sm:px-6 lg:px-8 lg:text-center">
                <h2 className="text-lg font-semibold text-emerald-600">
                    Valores
                </h2>
                <h2 className="mt-4 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                    Nuestra <p className="inline italic">Misión</p>
                </h2>
                <p className="mt-4 max-w-5xl text-xl text-gray-700 lg:mx-auto">
                    Que nunca falte energía.
                </p>
                <h2 className="mt-6 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                    Nuestra <p className="inline italic">Visión</p>
                </h2>
                <p className="mt-4 max-w-5xl text-xl text-gray-700 lg:mx-auto">
                    Implementar en nuestro entorno, las más eficientes tecnologías para un mundo sustentable.
                </p>
            </div>


        </>
    )
}
